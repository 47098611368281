import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { Link } from "gatsby";
import Logo from "../../assets/images/icons/logo.svg";
// import LogoMobile from "../../assets/images/icons/logo-white.svg";
import { BsChevronLeft } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

const Navbar = (props) => {
  const [linksActive, setLinksActive] = useState(false);

  const toggleMenu = () => {
    setLinksActive((p) => !p);
  };

  useEffect(() => {
    if (linksActive) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [linksActive]);

  return (
    <>
      <header
        // class="bg-white fixed w-full"
        className={
          "Navbar" +
          (props.serviceChosen ? " inBooking" : "") +
          (props.hiddenNav ? " hidden-nav" : "")
        }
        style={{ zIndex: 20 }}
      >
        {props.ableToGoBack && props.finishBookingStep >= 2 && (
          <button
            className="back"
            onClick={() => {
              props.setFinishBookingStep((p) => p - 1);
              window.scrollTo(0, 0);
            }}
          >
            <BsChevronLeft />
          </button>
        )}
        <Link
          to={props.chosenCity === "linkoping" ? "/linkoping/" : "/"}
          className="logo-mobile-link"
        >
          <Logo
            className={
              "logo-mobile" +
              (props.ableToGoBack && props.finishBookingStep >= 2
                ? " backable"
                : "")
            }
          />
        </Link>
        <Link to={"/"} className="logo-desktop-link">
          <Logo className="logo-desktop" />
        </Link>

        <div className="links">
          {!props.bookingActive && (
            <Link to={"/har-finns-vi"}>Här finns vi</Link>
          )}
          {!props.bookingActive && (
            <Link to={"/jobba-hos-oss"}>Jobba med oss</Link>
          )}
          {!props.bookingActive && <Link to="/om-oss">Om oss</Link>}
          <Link to="/booking" className="last-link">
            Boka fönsterputs
          </Link>
        </div>

        <div className="mobile-right">
          {!linksActive && !props.serviceChosen && (
            <Link to="/booking" className="last-link-mobile">
              Boka fönsterputs
            </Link>
          )}
          {linksActive ? (
            <div className="close-menu" onClick={toggleMenu} aria-hidden="true">
              <IoClose />
            </div>
          ) : (
            !props.serviceChosen && (
              <div
                className="burger-menu"
                onClick={toggleMenu}
                aria-hidden="true"
              >
                <div className="line" />
                <div className="line" />
                <div className="line" />
              </div>
            )
          )}
        </div>
      </header>
      <div className={"mobile-links" + (linksActive ? " active" : "")}>
        <div className="top-links">
          <Link to={"/har-finns-vi"}>Här finns vi</Link>
          <Link to="/om-oss">Om oss</Link>
          <Link to="/jobba-hos-oss">Jobba med oss</Link>
          <a href="#!">Jobba med oss</a>
        </div>
        <div className="bottom-btn">
          {!props.serviceChosen && <Link to="/booking">Boka fönsterputs</Link>}
        </div>
      </div>
    </>
  );
};

export default Navbar;
