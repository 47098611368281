import React from "react";
import Logo from "../../assets/images/icons/logo.svg";

const Footer = ({ chosenCity }) => {
  return (
    <footer style={{ background: "#011E41" }} aria-labelledby="footerHeading">
      <h2 id="footerHeading" class="sr-only">
        Footer
      </h2>
      <div class="lg:max-w-7xl max-w-2xl mx-auto pt-12 pb-6 px-6 sm:px-6 lg:pt-16 lg:px-8">
        <div class="lg:grid lg:grid-cols-4 lg:gap-8">
          <div class="space-y-8 lg:col-span-1 hidden lg:block">
            {/* <img
              class="h-10"
              src="https://tailwindui.com/img/logos/workflow-mark-gray-300.svg"
              alt="Company name"
            /> */}
            <Logo class="h-10 w-auto" />
            <p class="text-white text-base mt-2">
              Fönnsterputsfirma som startades med en vision att skapa en enklare
              vardag genom att erbjuda högsta kvalitet av service till lågt
              pris.
            </p>
          </div>
          <div class="lg:col-span-1"></div>
          <div class="grid grid-cols-2 gap-8 lg:col-span-2">
            <div>
              <h3 class="text-sm font-semibold text-white uppercase">
                Följ oss
              </h3>
              <ul class="mt-6 lg:mt-10 space-y-4">
                <li>
                  <a
                    href="https://www.facebook.com/putsojkpg"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-white text-base lg:text-sm flex items-center"
                  >
                    <svg
                      width="27"
                      height="27"
                      className="mr-3"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 0C6.04406 0 0 6.04406 0 13.5C0 20.9559 6.04406 27 13.5 27C20.9559 27 27 20.9559 27 13.5C27 6.04406 20.9559 0 13.5 0ZM16.6978 9.32906H14.6686C14.4281 9.32906 14.1609 9.64547 14.1609 10.0659V11.5313H16.6992L16.3153 13.6209H14.1609V19.8942H11.7661V13.6209H9.59344V11.5313H11.7661V10.3022C11.7661 8.53875 12.9895 7.10578 14.6686 7.10578H16.6978V9.32906Z"
                        fill="white"
                      />
                    </svg>
                    Facebook
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/putso.nu"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-white text-base lg:text-sm flex items-center"
                  >
                    <svg
                      width="27"
                      className="mr-3"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.084 13.5C16.084 14.9271 14.9271 16.084 13.5 16.084C12.0729 16.084 10.916 14.9271 10.916 13.5C10.916 12.0729 12.0729 10.916 13.5 10.916C14.9271 10.916 16.084 12.0729 16.084 13.5Z"
                        fill="white"
                      />
                      <path
                        d="M19.5429 8.92818C19.4187 8.59159 19.2205 8.28693 18.963 8.03685C18.7129 7.77936 18.4085 7.58119 18.0717 7.45698C17.7985 7.35089 17.3882 7.22462 16.6324 7.19022C15.8148 7.15293 15.5697 7.1449 13.4998 7.1449C11.4298 7.1449 11.1847 7.15273 10.3673 7.19001C9.61151 7.22462 9.20097 7.35089 8.92802 7.45698C8.59122 7.58119 8.28656 7.77936 8.03669 8.03685C7.7792 8.28693 7.58103 8.59139 7.45661 8.92818C7.35052 9.20133 7.22425 9.61188 7.18985 10.3677C7.15257 11.1851 7.14453 11.4302 7.14453 13.5002C7.14453 15.57 7.15257 15.8152 7.18985 16.6328C7.22425 17.3886 7.35052 17.7989 7.45661 18.072C7.58103 18.4088 7.77899 18.7133 8.03648 18.9634C8.28656 19.2209 8.59102 19.419 8.92782 19.5432C9.20097 19.6495 9.61151 19.7758 10.3673 19.8102C11.1847 19.8475 11.4296 19.8553 13.4996 19.8553C15.5699 19.8553 15.815 19.8475 16.6322 19.8102C17.388 19.7758 17.7985 19.6495 18.0717 19.5432C18.7477 19.2825 19.2821 18.7481 19.5429 18.072C19.649 17.7989 19.7752 17.3886 19.8098 16.6328C19.8471 15.8152 19.855 15.57 19.855 13.5002C19.855 11.4302 19.8471 11.1851 19.8098 10.3677C19.7754 9.61188 19.6492 9.20133 19.5429 8.92818ZM13.4998 17.4806C11.3013 17.4806 9.51902 15.6986 9.51902 13.5C9.51902 11.3014 11.3013 9.51939 13.4998 9.51939C15.6982 9.51939 17.4805 11.3014 17.4805 13.5C17.4805 15.6986 15.6982 17.4806 13.4998 17.4806ZM17.6378 10.2923C17.1241 10.2923 16.7076 9.87576 16.7076 9.36201C16.7076 8.84826 17.1241 8.43174 17.6378 8.43174C18.1516 8.43174 18.5681 8.84826 18.5681 9.36201C18.5679 9.87576 18.1516 10.2923 17.6378 10.2923Z"
                        fill="white"
                      />
                      <path
                        d="M13.5 0C6.0453 0 0 6.0453 0 13.5C0 20.9547 6.0453 27 13.5 27C20.9547 27 27 20.9547 27 13.5C27 6.0453 20.9547 0 13.5 0ZM21.2052 16.696C21.1677 17.5212 21.0365 18.0846 20.8449 18.5777C20.4422 19.619 19.619 20.4422 18.5777 20.8449C18.0848 21.0365 17.5212 21.1675 16.6962 21.2052C15.8695 21.2429 15.6055 21.252 13.5002 21.252C11.3947 21.252 11.1309 21.2429 10.304 21.2052C9.479 21.1675 8.91541 21.0365 8.42246 20.8449C7.90501 20.6502 7.43658 20.3452 7.04931 19.9507C6.65504 19.5636 6.34996 19.095 6.1553 18.5777C5.96372 18.0848 5.8325 17.5212 5.79501 16.6962C5.7569 15.8693 5.74805 15.6053 5.74805 13.5C5.74805 11.3947 5.7569 11.1307 5.79481 10.304C5.8323 9.4788 5.96331 8.91541 6.15488 8.42226C6.34955 7.90501 6.65483 7.43637 7.04931 7.04931C7.43637 6.65483 7.90501 6.34975 8.42226 6.15509C8.91541 5.96352 9.4788 5.8325 10.304 5.79481C11.1307 5.75711 11.3947 5.74805 13.5 5.74805C15.6053 5.74805 15.8693 5.75711 16.696 5.79501C17.5212 5.8325 18.0846 5.96352 18.5777 6.15488C19.095 6.34955 19.5636 6.65483 19.9509 7.04931C20.3452 7.43658 20.6505 7.90501 20.8449 8.42226C21.0367 8.91541 21.1677 9.4788 21.2054 10.304C21.2431 11.1307 21.252 11.3947 21.252 13.5C21.252 15.6053 21.2431 15.8693 21.2052 16.696Z"
                        fill="white"
                      />
                    </svg>
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-sm font-semibold text-white uppercase">
                Kundsupport
              </h3>
              <ul class="mt-6 lg:mt-10 space-y-4">
                <li>
                  <a
                    href="tel:036141500"
                    class="text-base lg:text-lg text-white flex footer-link-wrap"
                  >
                    <svg
                      className="mr-4"
                      width="18"
                      height="27"
                      viewBox="0 0 18 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.6763 11.9117V23.0294C16.6763 23.8718 16.3416 24.6798 15.7459 25.2755C15.1502 25.8712 14.3423 26.2058 13.4998 26.2058H3.97042C3.12796 26.2058 2.32002 25.8712 1.72431 25.2755C1.12861 24.6798 0.793945 23.8718 0.793945 23.0294V3.97054C0.793945 3.12809 1.12861 2.32014 1.72431 1.72443C2.32002 1.12873 3.12796 0.794067 3.97042 0.794067H13.4998C14.3423 0.794067 15.1502 1.12873 15.7459 1.72443C16.3416 2.32014 16.6763 3.12809 16.6763 3.97054V8.33818"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M0.793945 22.0367H16.284M0.793945 5.36023H16.284H0.793945Z"
                        stroke="white"
                        stroke-width="1.5"
                      />
                    </svg>
                    036-141500
                  </a>
                </li>
                <li>
                  <a
                    href={`mailto:info@putso.nu`}
                    class="text-base lg:text-lg text-white flex footer-link-wrap"
                  >
                    <svg
                      className="mr-2"
                      width="27"
                      height="21"
                      viewBox="0 0 27 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.29541 16.151V4.30369C1.29541 2.37851 2.75505 0.818237 4.55586 0.818237H22.5763C24.3763 0.818237 25.8368 2.37851 25.8368 4.30369V16.151C25.8368 18.0761 24.3771 19.6364 22.5763 19.6364H4.55586"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M25.0188 2.91284L21.9833 5.15875L13.5659 11.3892L2.11377 2.91284"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    info@putso.nu
                  </a>
                </li>
                <li className="text-white text-sm hidden lg:block">
                  Kundservice har öppet måndag-fredag kl. 8:00-17:00
                </li>
              </ul>
            </div>
          </div>
          <p class="text-white text-sm lg:hidden text-center mt-8 mb-6">
            Kundservice har öppet måndag-fredag kl. 8:00-17:00
          </p>
        </div>
        <div class="mt-8 lg:mt-12 pt-2">
          <p class="text-sm text-center lg:text-left lg:text-base text-white">
            Putso AB | Organisationsnummer: 559367-3394{" "}
            <span className="hidden lg:inline-block">| Integritetspolicy</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
