import React from "react";
import "./Layout.scss";
import "../../styles/globals.scss";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Wrapper from "../Wrapper/Wrapper";

const Layout = (props) => {
  return (
    <Wrapper>
      {/* {!loaded && <Loader />}
			{!chosenCity && <ChooseCity />} */}
      <div className={"Layout" + (props.bookingActive ? " book-active" : "")}>
        <Navbar
          bookingActive={props.bookingActive}
          serviceChosen={props.serviceChosen}
          ableToGoBack={props.ableToGoBack}
          setFinishBookingStep={props.setFinishBookingStep}
          finishBookingStep={props.finishBookingStep}
          chosenCity={""}
          hiddenNav={props.hiddenNav}
        />
        {props.children}
        {!props.bookingActive && <Footer chosenCity={""} />}
      </div>
    </Wrapper>
  );
};

export default Layout;
